<template>
  <div class="course-details-container">
    <div class="content">
      <el-button
        type="primary"
        size="small"
        class="teachManage-tool-btnicon goback-btn"
        @click="toBack"
      >
        <i class="icon-navtop-back"></i>{{ $t("el.common.back") }}</el-button
      >

      <div class="content-detail" v-loading="allLoading">
        <course-details-header :courseObj="courseObj"></course-details-header>
        <course-details-content
          style="margin-top: 14px"
          :allowed="allowed"
          :htmlText="htmlText"
          :isTry="courseObj.isTry"
          :courseTenantInfoId="courseObj.courseTenantId"
          :knowledgeList="knowledgeList"
        ></course-details-content>
      </div>
    </div>
  </div>
</template>

<script>
import CourseDetailsHeader from "./components/courseDetailHeader.vue";
import CourseDetailsContent from "./components/courseDetailContent.vue";
import { getCourseDetail } from "../../api/courseList/index";
export default {
  data() {
    return {
      courseObj: {},
      knowledgeList: [],
      htmlText: "",
      allowed: -1,
      allLoading: false,
      id: "",
      originTenantId: "",
      originTenantType: "",
    };
  },
  provide() {
    return {
      reactiveMsg: () => {
        return {
          courseId: this.id,
          originTenantId: this.originTenantId,
          originTenantType: this.originTenantType,
          courseTenantInfoId: this.courseObj.courseTenantId,
        };
      },
    };
  },
  created() {
    this.id = this.$route.query.id || "";
    this.originTenantId = this.$route.query.originTenantId || "";
    this.originTenantType = this.$route.query.originTenantType || "";
    if (this.id) {
      this.getCourseDetail({
        courseId: this.id,
        originTenantId: this.originTenantId,
        originTenantType: this.originTenantType,
      });
    } else {
      this.$message.error(this.$t("el.common.courseDetails"));
    }
  },
  methods: {
    // 课程详情
    getCourseDetail(data) {
      this.allLoading = true;
      return getCourseDetail(data)
        .then((result) => {
          // console.log("result:",result);
          this.courseObj = result.data;
          let idx = 0;
          if (result.data && result.data.knowledgeList) {
            result.data.knowledgeList.forEach((element) => {
              element.children.forEach((ele) => {
                ele.idx = idx;
                idx = idx + 1;
              });
            });
            this.knowledgeList = result.data.knowledgeList;
            // this.knowledgeList.push(...result.data.knowledgeList);
            // this.knowledgeList.push(...result.data.knowledgeList);
            // this.knowledgeList.push(...result.data.knowledgeList);
            // this.knowledgeList.push(...result.data.knowledgeList);
            this.htmlText = result.data.courseIntroduce;
            this.allowed = result.data.allowed || 0;
          } else {
            this.knowledgeList = [];
            this.htmlText = "";
          }
          this.allLoading = false;
        })
        .catch(() => {
          this.courseObj = {};
          this.knowledgeList = [];
          this.htmlText = "";
          this.allLoading = false;
        });
    },
    toBack() {
      // let detailsFromPage = localStorage.getItem("detailsFromPage");
      // let courseListId = localStorage.getItem("courseListId");
      // if (detailsFromPage && detailsFromPage === "courseList") {
      //   this.$router.replace({
      //     name: detailsFromPage,
      //     query: {
      //       id: courseListId,
      //     },
      //   });
      // } else {
      //   this.$router.replace({
      //     name: "myCourses",
      //   });
      // }
      this.$router.go(-1);
    },
  },
  components: {
    CourseDetailsHeader,
    CourseDetailsContent,
  },
};
</script>

<style lang="less" scoped>
.course-details-container {
  .content {
    padding-top: 32px;
    .goback-btn {
      border-radius: 20px;
    }
    &-detail {
      margin-top: 14px;
    }
  }
  // 图标
  .icon-navtop-back {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    color: #fff;
    .gen-icon(white_navtop_back);
  }
}
</style>
