var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"details-container"},[_c('el-tabs',{ref:"courseTabs",staticStyle:{"height":"100%"},model:{value:(_vm.activeName),callback:function ($$v) {_vm.activeName=$$v},expression:"activeName"}},[_c('el-tab-pane',{attrs:{"label":_vm.$t('el.courseDetails.details'),"name":"first"}},[(_vm.htmlText)?_c('v-html',{attrs:{"htmlText":_vm.htmlText}}):_c('div',{staticStyle:{"text-align":"center","padding-top":"40px"}},[_c('img',{staticClass:"no-img",attrs:{"src":require("@/assets/images/subjectType/pic_empty_course@2x.png"),"alt":_vm.$t('el.courseDetails.centerName'),"title":_vm.$t('el.courseDetails.centerName')}}),_c('div',[_vm._v(_vm._s(_vm.$t("el.common.noIntroduction")))])])],1),_c('el-tab-pane',{staticClass:"details-catalog",attrs:{"label":_vm.$t('el.courseDetails.content'),"name":"second"}},[(_vm.allowed === 0)?_c('div',{staticClass:"no-allewed-content"},[_c('span',{staticClass:"no-allewed-text"},[_vm._v(_vm._s(_vm.$t("el.courseDetails.noPermissionCourseContact")))])]):_vm._e(),(_vm.knowledgeList.length > 0)?_c('div',_vm._l((_vm.knowledgeList),function(item,index){return _c('div',{key:item.id,staticClass:"details-chapter"},[_c('div',{staticClass:"flex flex-m details-chapter-header"},[_c('el-image',{staticClass:"details-chapter-icon",attrs:{"src":_vm.iconChapter,"fit":"fit"}}),_c('div',{staticClass:"details-chapter-title"},[_c('div',[_vm._v("U"+_vm._s(index + 1))]),_c('div',{staticClass:"u-line",staticStyle:{"margin-top":"-10px"}})]),_c('span',{staticClass:"details-chapter-title"},[_vm._v(_vm._s(item.knowledgeName))])],1),_vm._l((item.children),function(obj,idx){return _c('div',{key:obj.id},[_c('div',{staticClass:"flex flex-m details-section"},[_c('span',{directives:[{name:"check-bsk-permission",rawName:"v-check-bsk-permission",value:({
                  handler: _vm.prepareBack,
                  handlerArgs: [obj],
                  args: {
                    courseId: _vm.courseId,
                    originTenantId: _vm.originTenantId,
                  },
                  checkRequestStatus: 'isRequestPending',
                  noInnerChangePending: true,
                }),expression:"{\n                  handler: prepareBack,\n                  handlerArgs: [obj],\n                  args: {\n                    courseId: courseId,\n                    originTenantId: originTenantId,\n                  },\n                  checkRequestStatus: 'isRequestPending',\n                  noInnerChangePending: true,\n                }"}]},[_vm._v(_vm._s(idx + 1 + "、" + obj.knowledgeName))]),(obj.isTeacherTrain)?_c('span',{staticClass:"details-section-instructions"},[_vm._v(_vm._s(_vm.$t("el.classroomWps.teacherInstructions")))]):_vm._e(),(_vm.allowed && (!_vm.isTry || (_vm.isTry && obj.idx < 3)))?_c('div',{staticClass:"flex details-section-btn"},[_c('div',{directives:[{name:"check-bsk-permission",rawName:"v-check-bsk-permission",value:({
                    handler: _vm.prepareBack,
                    handlerArgs: [obj],
                    args: {
                      courseId: _vm.courseId,
                      originTenantId: _vm.originTenantId,
                    },
                    checkRequestStatus: 'isRequestPending',
                    noInnerChangePending: true,
                  }),expression:"{\n                    handler: prepareBack,\n                    handlerArgs: [obj],\n                    args: {\n                      courseId: courseId,\n                      originTenantId: originTenantId,\n                    },\n                    checkRequestStatus: 'isRequestPending',\n                    noInnerChangePending: true,\n                  }"}],staticClass:"details-section-btn-bk"},[_vm._v(" "+_vm._s(_vm.$t("el.courseDetails.prepareLessons"))+" ")])]):_vm._e(),(_vm.isTry && obj.idx > 2)?_c('el-image',{staticClass:"details-section-lock",attrs:{"src":_vm.iconLock}}):_vm._e()],1)])}),(index !== _vm.knowledgeList.length - 1)?_c('div',{staticClass:"dash-line"}):_vm._e()],2)}),0):(_vm.knowledgeList.length === 0)?_c('div',{staticStyle:{"text-align":"center","padding-top":"40px"}},[_c('img',{staticClass:"no-img",attrs:{"src":require("@/assets/images/subjectType/pic_empty_course@2x.png"),"alt":_vm.$t('el.courseDetails.centerName'),"title":_vm.$t('el.courseDetails.centerName')}}),_c('div',[_vm._v(_vm._s(_vm.$t("el.common.noData")))])]):_vm._e()])],1),(_vm.dialogAuth)?_c('AuthDialog',{ref:"authConfig"}):_vm._e(),(_vm.showTeachingSelectDialog)?_c('teaching-select-dialog',{ref:"teachingSelectDialog",attrs:{"isTry":_vm.isTry}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }