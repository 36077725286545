<template>
  <div class="details-header-content">
    <div class="details-header-content-title">
      {{ $t("el.courseDetails.courseDetails") }}
    </div>
    <div class="flex flex-m">
      <div style="position: relative">
        <el-image
          class="details-header-icon"
          :src="courseObj.courseCover || defaultImage"
          fit="cover"
          @click="courseObj.courseVideo ? onHandleCourseCover : null"
          :preview-src-list="
            courseObj.courseVideo ? [] : [courseObj.courseCover || defaultImage]
          "
        >
        </el-image>
        <i
          v-if="courseObj.courseVideo"
          @click="onHandleCourseCover"
          class="el-icon-video-play play-video-icon"
        ></i>
      </div>

      <div class="details-course">
        <span class="details-course-name">{{ courseObj.courseName }}</span>
        <span v-if="courseObj.isTry" class="details-course-try">{{
          $t("el.common.onTrial")
        }}</span>
        <div style="display: flex; align-items: center">
          <div class="details-course-point">
            {{ $t("el.courseDetails.total") }} {{ courseObj.knowledgeNum }}
            {{ $t("el.courseDetails.keyPoints") }}
          </div>
          <div style="margin-left: 20px; margin-bottom: 8px">
            可用时段:
            {{
              courseObj?.availableTimePeriodsVO
                ?.map(
                  (item) =>
                    `${item.name} (${secondsToTime(
                      item.startTime
                    )} - ${secondsToTime(item.endTime)}) `
                )
                .join("、")
            }}
          </div>
        </div>
        <div class="flex flex-m">
          <el-image
            class="details-course-icon"
            :src="iconCourseCategoryLabel"
            fit="cover"
          ></el-image>
          {{ $t("el.courseDetails.types") }}
          {{
            courseObj.pCategoryName
              ? courseObj.pCategoryName + "/" + courseObj.categoryName
              : $t("el.common.No")
          }}
        </div>
        <div class="details-course-desc">
          <span class="details-course-subject"
            >{{ $t("el.courseDetails.Subject") }}
            {{ courseObj.subjectName }}</span
          >
          <span class="details-course-grade"
            >{{ $t("el.courseDetails.Grade") }} {{ courseObj.gradeName }}</span
          >
          <span
            >{{ $t("el.courseDetails.Semester") }}
            {{ courseObj.termName }}</span
          >
        </div>
      </div>
    </div>
    <el-dialog
      :title="courseObj.courseName"
      class="video-dialog"
      width="50%"
      :visible.sync="showDialog"
    >
      <video-player
        v-if="courseObj.courseVideo"
        class="video-player vjs-custom-skin"
        ref="videoPlayer"
        :playsinline="true"
        style="object-fit: fill"
        :options="
          getPlayerOptions(courseObj.courseVideo, courseObj.courseCover)
        "
        :x5-video-player-fullscreen="true"
        @pause="onPlayerPause($event)"
        @play="onPlayerPlay($event)"
        @click="fullScreen"
      >
      </video-player>
    </el-dialog>
  </div>
</template>

<script>
import { timeToSeconds, secondsToTime } from "@/utils";
import { videoPlayer } from "vue-video-player";
import "video.js/dist/video-js.css";
export default {
  data() {
    return {
      secondsToTime,
      iconCourseCategoryLabel: require("../../../assets/images/icon/icon-course-category-label.png"),
      defaultImage: require("@/assets/images/default-image.png"), // 默认封面
      showDialog: false,
    };
  },
  components: {
    videoPlayer,
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  props: {
    courseObj: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    onHandleCourseCover() {
      this.showDialog = true;
    },
    fullScreen() {
      const player = this.$refs.videoPlayer.player;
      player.requestFullscreen(); // 调用全屏api方法
      player.isFullscreen(true);
      player.play();
    },
    onPlayerPlay(player) {
      player.play();
    },
    onPlayerPause(player) {
      // alert("pause");
    },
    getPlayerOptions(video, coverUrl) {
      let type =
        video.substring(video.lastIndexOf(".") + 1) === "mov"
          ? "quicktime"
          : "mp4";
      return {
        //        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "video/mp4",
            src: video,
          },
          {
            type: "video/" + type,
            src: video,
          },
          {
            type: "video/ogg",
            src: video,
          },
          {
            type: "video/webm",
            src: video,
          },
        ],
        poster: coverUrl,
        width: document.documentElement.clientWidth,
        notSupportedMessage: this.$t("el.common.supportVideoPlayNot"), // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          // timeDivider: true,
          // durationDisplay: true,
          // remainingTimeDisplay: false,
          fullscreenToggle: true, // 全屏按钮
        },
      };
    },
  },
};
</script>

<style lang="less" scoped>
.details-header-content {
  //   height: 228px;
  background: #ffffff;
  border-radius: 6px;
  padding: 28px 21px 33px;
  position: relative;

  .bg-details-head {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 244px;
    height: 186px;
    background: url("../../../assets/images/icon/bg-details-head.png") no-repeat;
    background-size: 100% 100%;
  }

  .video-dialog {
    /deep/ .el-dialog__header {
      padding: 20px 20px px 0px;
      text-align: left;
    }
  }

  .play-video-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    font-size: 36px;
  }
  &-title {
    font-size: 20px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #131313;
    margin-bottom: 27px;
  }
  .details-header-icon {
    width: 120px;
    height: 120px;
    border-radius: 6px;
    margin-left: 9px;
  }
  .details-course {
    margin-left: 21px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    &-name {
      font-size: 18px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #131313;
    }
    &-try {
      padding: 3px 12px;
      background: rgba(0, 118, 244, 0.1);
      border-radius: 12px;
      font-size: 12px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #0076f4;
      margin-left: 12px;
    }
    &-point {
      width: fit-content;
      height: 23px;
      padding-left: 8px;
      padding-right: 8px;
      background: rgba(255, 158, 16, 0.17);
      border-radius: 2px;

      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #ff9e10;
      line-height: 23px;
      text-align: center;
      margin-bottom: 19px;
      margin-top: 10px;
    }
    &-icon {
      width: 14px;
      height: 14px;
      margin-right: 6px;
    }
    &-desc {
      margin-top: 10px;
    }
    &-subject {
      margin-right: 63px;
      margin-left: 20px;
    }
    &-grade {
      margin-right: 63px;
    }
  }
}
/deep/ .video-js .vjs-big-play-button {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: #2b333f;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
}
</style>
<style lang="less"></style>
